<template>

  <v-app>

    <dashboard-core-app-bar />

    <dashboard-core-drawer />

    <dashboard-core-le-not-required-view v-if="!leRequired" />
    
    <dashboard-core-le-required-view v-if="leRequired" />

  </v-app>

</template>

<script>
import jwt_decode from "jwt-decode";
  export default {
    name: 'DashboardIndex',

    components: {
      DashboardCoreAppBar: () => import('./components/core/AppBar'),
      DashboardCoreDrawer: () => import('./components/core/Drawer'),
      DashboardCoreLeNotRequiredView: () => import('./components/core/LE-Not-Required-View'),
      DashboardCoreLeRequiredView: () => import('./components/core/LE-Required-View'),
    },

    computed: {
        userProfile() { return this.$store.getters.userProfile },
        leRequired () { return this.$route.meta.leRequired }
    },

    created () {
      const token = window.localStorage.getItem('token')
      if (!token) {
        this.$router.push({name: 'login'})
      }
      else {
        var decoded = jwt_decode(token);
        if (Date.now() >= decoded.exp * 1000) {
          this.$router.push({name: 'login'})
          return;
        }

        this.$store.dispatch('loadUserProfile')
        this.$store.dispatch('loadLegalEntities')
        this.$store.dispatch('loadBrands')
      }
    },

    data: () => ({
      expandOnHover: false,
    }),
  }
</script>
